import React, { useEffect, useState } from 'react';

import './App.scss';

const App = () => {
	const answers = [
		// Mercedes
		{
			"brand": "Mercedes-Benz",
			"correct": "G-Class",
			"incorrect": "E-Class",
			"image": "https://autoua.net/media/uploads/raznoe/mercedes-amg-g-class-with-v12-engine-from-brabus.jpg"
		},
		// Lambo
		{
			"brand": "Lamborghini",
			"correct": "Urus",
			"incorrect": "Huracan",
			"image": "https://i2.wp.com/autosbravo.es/wp-content/uploads/2021/05/Lamborghini-Urus-1.png?fit=700%2C525&ssl=1"
		},
		// Lambo
		{
			"brand": "Lamborghini",
			"correct": "Aventador",
			"incorrect": "Huracan",
			"image": "https://www.gt-luxury.ae/wp-content/uploads/2021/03/ptf-lamborghini00.png"
		},
		// Lambo
		{
			"brand": "Lamborghini",
			"correct": "Terzo",
			"incorrect": "Veneno",
			"image": "https://www.pngplay.com/wp-content/uploads/13/Lamborghini-Terzo-Millennio-Transparent-Background.png"
		},
		// McLaren
		{
			"brand": "McLaren",
			"correct": "P1",
			"incorrect": "F1",
			"image": "https://images.wapcar.my/file/7ccd03e5555a4102bb788319085d6f46.jpg"
		},
		// Fisker
		{
			"brand": "Fisker",
			"correct": "Karma",
			"incorrect": "Ocean",
			"image": "https://cdn.dealeraccelerate.com/rkm/1/4253/293009/790x1024/2012-fisker-karma-signature"
		},
		// Bugatti
		{
			"brand": "Bugatti",
			"correct": "Veyron",
			"incorrect": "Chiron",
			"image": "https://i.pinimg.com/originals/d9/02/5e/d9025e3a3f3593da44bd48d85297d8fd.png"
		},
		// Mercedes
		{
			"brand": "Mercedes-Benz",
			"correct": "GLS",
			"incorrect": "GLA",
			"image": "https://mercedes-benz.kiev.ua/storage/cars/611f74ac70d99.png"
		},
		// Nissan
		{
			"brand": "Nissan",
			"correct": "Skyline",
			"incorrect": "Silvia",
			"image": "https://www.pngmart.com/files/22/Nissan-Skyline-GT-R-R34-PNG-File.png"
		},
		// BMW
		{
			"brand": "BMW",
			"correct": "X3",
			"incorrect": "X5",
			"image": "https://media.adtorqueedge.com/new-cars/bmw-aus/x3/variant/x3-20id.png"
		},
		// BMW
		{
			"brand": "BMW",
			"correct": "i3",
			"incorrect": "i7",
			"image": "https://fdrivesalon.com.ua/Media/images/catalog/medium/43f16356436f807c2df20ee4f3909ec4.png"
		},
		// ВАЗ
		{
			"brand": "Жигулі",
			"correct": "ВАЗ-2105",
			"incorrect": "ВАЗ-2107",
			"image": "https://deb1102ca7.clvaw-cdnwnd.com/36948f2c596cac4576560e44a345ca38/200012710-aeb2aaeb2c/vaz_2105%20(1).png"
		},
		// CARS
		{
			"correct": "McQueen",
			"incorrect": "Mater",
			"image": "https://images.squarespace-cdn.com/content/v1/51cdafc4e4b09eb676a64e68/1470951917131-VO6KK2XIFP4LPLCYW7YU/McQueen15.jpg"
		},
		// ROLLS
		{
			"brand": "Rolls-Royce",
			"correct": "Ghost",
			"incorrect": "Phantom",
			"image": "https://platform.cstatic-images.com/xlarge/in/v2/stock_photos/37947b1d-4f3d-4a06-b780-6555f81f6708/b30f9ce3-7b74-407c-8893-e99c2fd57b6d.png"
		},
		// Plymouth
		{
			"brand": "Plymouth",
			"correct": "Cuda",
			"incorrect": "GTX",
			"image": "https://www.copart.com/content/cuda.png"
		},
		// Chevy
		{
			"brand": "Chevrolet",
			"correct": "Camaro",
			"incorrect": "Corvette",
			"image": "https://platform.cstatic-images.com/xlarge/in/v2/stock_photos/fa83d3a2-d8a2-4274-a198-ff9447cb1b2d/1f4f8b41-51d9-482f-8da5-f7018540e052.png"
		},
		// Ferarri
		{
			"brand": "Ferarri",
			"correct": "Italia",
			"incorrect": "LaFerarri",
			"image": "https://i.pinimg.com/originals/38/96/b4/3896b41d4a0266beb024cc8b94d55128.jpg"
		},
		// Toyota
		{
			"brand": "Toyota",
			"correct": "Land Cruser 200",
			"incorrect": "Land Cruser 300",
			"image": "https://d8a6a33f-3369-444b-9b5f-793c13ff0708.selcdn.net/media/common/car_slider_item_original/tradeins.space/uploads/photo/1577535/5394e9aa4a27de182c99b5600b0a7432.png?v188"
		},
		// Land Rover
		{
			"brand": "Land Rover",
			"correct": "Range Rover Sport",
			"incorrect": "Range Rover Velar",
			"image": "https://di-uploads-pod10.dealerinspire.com/landroverparamus/uploads/2018/05/2018-Land-Rover-Range-Rover-Sport.png"
		},
		// Lambo
		{
			"brand": "Lamborghini",
			"correct": "Veneno",
			"incorrect": "Sesto",
			"image": "https://img.tinbanxe.vn/images/Lamborghini/Lamborghini%20Veneno/A9_Veneno_YL-removebg-preview.png"
		},
		// Tesla
		{
			"brand": "Tesla",
			"correct": "Model 3",
			"incorrect": "Model S",
			"image": "https://fdrivesalon.com.ua/Media/images/catalog/medium/88092141dd407bb3937462db928c7bc1.png"
		},
		// Tesla
		{
			"brand": "Tesla",
			"correct": "Model Y",
			"incorrect": "Model X",
			"image": "https://usa.tsk.ua/uploads/images/vehicle_models/4/model-y.png"
		},
		// BYD
		{
			"brand": "BYD",
			"correct": "Han",
			"incorrect": "Tang",
			"image": "https://bydauto.co.il/wp-content/uploads/2023/04/HAN-green01.png"
		},
		// Volvo
		{
			"brand": "Volvo",
			"correct": "XC60",
			"incorrect": "XC90",
			"image": "https://platform.cstatic-images.com/xlarge/in/v2/stock_photos/383da16b-0528-4c34-a13e-f07dc3e9751e/d3a88923-89d7-414e-859c-672ad97b6549.png"
		},
		// Koenigsegg
		{
			"brand": "Koenigsegg",
			"correct": "Gemera",
			"incorrect": "Agera",
			"image": "https://freebiescloud.com/wp-content/uploads/2022/03/Koenigsegg-Gemera-2021-5.png"
		},
		// Запорожець
		{
			"brand": "Запорожець",
			"correct": "ЗАЗ-968М",
			"incorrect": "ЗАЗ-966",
			"image": "https://modelist-konstruktor.com/wp-content/uploads/2020/06/1-39.jpg"
		},
		// WV
		{
			"brand": "Volkswagen",
			"correct": "Golf",
			"incorrect": "Polo",
			"image": "https://www.motortrend.com/uploads/sites/10/2022/05/2021-volkswagen-golf-tsi-5door-hatchback-angular-front.png"
		},
		// Fiat
		{
			"brand": "Fiat",
			"correct": "Panda",
			"incorrect": "Punto",
			"image": "https://www.sapphirerental.com/files/CarRentalGallery/fiat-panda-2018.png"
		},
		// Buick
		{
			"brand": "Buick",
			"correct": "Grand National",
			"incorrect": "Park Avenue",
			"image": "https://cdn.shopify.com/s/files/1/0552/1247/4499/files/RoadsterShopsBuickGrandNational_4.8Update-1_1_-removebg-preview_600x600.webp?v=1697502551"
		},
	];

	const [correctOption, setCorrectOption] = useState("");
	const [incorrectOption, setIncorrectOption] = useState("");

	const [brand, setBrand] = useState("");
	const [imageURL, setImageURL] = useState("");

	const [selectedindex, setSelectedindex] = useState(0);

	const [correctAnwserPosition, setCorrectAnwserPosition] = useState("normal");

	const [message, setMessage] = useState({type: "", text: ""});

	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const [answersGivenNumber, setAnswersGivenNumber] = useState(0);
	const [triesIndexes, setTriesIndexes] = useState([]);

	const [isAnswerCorrect, setIsAnswerCorrect] = useState(true);

	const correctAnswers = [
		<><small>Саме так! Ти знав, що це</small><br/><b>{brand} {correctOption}</b></>,
		<><small>Ну, звісно! Це ж</small><br/><b>{brand} {correctOption}</b></>,
		<><small>Так! Це </small><br/><b>{brand} {correctOption}</b></>,
		<><small>Ти все знаєш! Це </small><br/><b>{brand} {correctOption}</b></>,
		<><small>Для тебе, це легко! Перед тобою </small><br/><b>{brand} {correctOption}</b></>,
	];

	const incorrectAnswers = [
		<><small>Та ні... Ти помилився. Це ж</small><br/><b>{brand} {correctOption}</b></>,
		<><small>Здається, ти не знаєш, що це</small><br/><b>{brand} {correctOption}</b></>,
		<><small>Ні. Це </small><br/><b>{brand} {correctOption}</b></>,
		<><small>Хіба?. Це ж </small><br/><b>{brand} {correctOption}</b></>,
		<><small>Майже... На фото </small><br/><b>{brand} {correctOption}</b></>
	];

	useEffect(() => {
		console.log('Component mounted');

		pickRandomQuestion();

		return () => {
			console.log('Component will unmount');
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, [loading]);

	const pickRandomQuestion = () => {
		// console.log("pickRandomQuestion");

		const randomCorrectAnswerPoition = Math.round(0 + Math.random() * (1 - 0));
		// console.log(randomCorrectAnswerPoition);

		if(randomCorrectAnswerPoition === 1){
			setCorrectAnwserPosition("inverted");
		}else{
			setCorrectAnwserPosition("normal");
		}

		const randomIndex = Math.floor(Math.random() * answers.length);
		console.log(randomIndex);

		if(randomIndex == selectedindex){
			console.log(" Naa! This is the same one...");

			pickRandomQuestion();
		}else{
			setSelectedindex(randomIndex);
			
			setCorrectOption(answers[randomIndex].correct);
			setIncorrectOption(answers[randomIndex].incorrect);

			setBrand(answers[randomIndex].brand);
			setImageURL(answers[randomIndex].image);
		}
	}

	const submit = (e, correct) => {
		console.log("submit");

		// Count answers
		setAnswersGivenNumber(answersGivenNumber + 1);
		console.log(answersGivenNumber + 1 + " of " + answers.length);

		setSubmitted(true);

		if( correct ){
			const randomCorrectAnswerIndex = Math.floor(Math.random() * correctAnswers.length);

			setMessage({
				type: "success",
				text: correctAnswers[randomCorrectAnswerIndex]
			});
		}else{
			const randomIncorrectAnswerIndex = Math.floor(Math.random() * incorrectAnswers.length);
			
			setMessage({
				type: "error",
				text: incorrectAnswers[randomIncorrectAnswerIndex]
			});
		}

		setIsAnswerCorrect(correct);
	}

	const next = () => {
		setLoading(true);

		setSubmitted(false);
		setMessage({
			type: "",
			text: ""
		});

		setImageLoaded(false);
		pickRandomQuestion();
	}

	const handleImageLoaded = () => {
		setTimeout(() => {
			setImageLoaded(true);
		}, 500);
	}

	const controls = [
		<button key={0} onClick={(e) => submit(e, true)}><b>{correctOption}</b></button>,
		<button key={1} onClick={(e) => submit(e, false)}><b>{incorrectOption}</b></button>
	];

	return (
		<div className="app">
			{/* {loading &&
				<div className='loading'></div>
			} */}

			{!imageLoaded &&
				<div className='loading'></div>
			}

			<div className='header'>
				{message.text ?
					<div className={'message' + (message.type ? " " + message.type : "") }>
						<p>{message.text}</p>
					</div>
				:
					<div className={'message question'}>
						<p><small>Яка це модель авто?</small><br/>Це <b>{brand}</b>...</p>
					</div>
				}
			</div>

			<div className='main'>
				<div className={'image-wrap' + (imageLoaded ? " image-loading": "")}>
					<img src={imageURL} onLoad={handleImageLoaded}/>
				</div>
			</div>

			<div className='footer'>
				{submitted ?
					<>
						{isAnswerCorrect ?
							<img width={48} src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDY2LjI3IDI4Ni42OUM0NzUuMDQgMjcxLjg0IDQ4MCAyNTYgNDgwIDIzNi44NWMwLTQ0LjAxNS0zNy4yMTgtODUuNTgtODUuODItODUuNThIMzU3LjdjNC45Mi0xMi44MSA4Ljg1LTI4LjEzIDguODUtNDYuNTRDMzY2LjU1IDMxLjkzNiAzMjguODYgMCAyNzEuMjggMGMtNjEuNjA3IDAtNTguMDkzIDk0LjkzMy03MS43NiAxMDguNi0yMi43NDcgMjIuNzQ3LTQ5LjYxNSA2Ni40NDctNjguNzYgODMuNEgzMmMtMTcuNjczIDAtMzIgMTQuMzI3LTMyIDMydjI0MGMwIDE3LjY3MyAxNC4zMjcgMzIgMzIgMzJoNjRjMTQuODkzIDAgMjcuNDA4LTEwLjE3NCAzMC45NzgtMjMuOTUgNDQuNTA5IDEuMDAxIDc1LjA2IDM5Ljk0IDE3Ny44MDIgMzkuOTQgNy4yMiAwIDE1LjIyLjAxIDIyLjIyLjAxIDc3LjExNyAwIDExMS45ODYtMzkuNDIzIDExMi45NC05NS4zMyAxMy4zMTktMTguNDI1IDIwLjI5OS00My4xMjIgMTcuMzQtNjYuOTkgOS44NTQtMTguNDUyIDEzLjY2NC00MC4zNDMgOC45OS02Mi45OXptLTYxLjc1IDUzLjgzYzEyLjU2IDIxLjEzIDEuMjYgNDkuNDEtMTMuOTQgNTcuNTcgNy43IDQ4Ljc4LTE3LjYwOCA2NS45LTUzLjEyIDY1LjloLTM3LjgyYy03MS42MzkgMC0xMTguMDI5LTM3LjgyLTE3MS42NC0zNy44MlYyNDBoMTAuOTJjMjguMzYgMCA2Ny45OC03MC44OSA5NC41NC05Ny40NiAyOC4zNi0yOC4zNiAxOC45MS03NS42MyAzNy44Mi05NC41NCA0Ny4yNyAwIDQ3LjI3IDMyLjk4IDQ3LjI3IDU2LjczIDAgMzkuMTctMjguMzYgNTYuNzItMjguMzYgOTQuNTRoMTAzLjk5YzIxLjExIDAgMzcuNzMgMTguOTEgMzcuODIgMzcuODIuMDkgMTguOS0xMi44MiAzNy44MS0yMi4yNyAzNy44MSAxMy40ODkgMTQuNTU1IDE2LjM3MSA0NS4yMzYtNS4yMSA2NS42MnpNODggNDMyYzAgMTMuMjU1LTEwLjc0NSAyNC0yNCAyNHMtMjQtMTAuNzQ1LTI0LTI0IDEwLjc0NS0yNCAyNC0yNCAyNCAxMC43NDUgMjQgMjR6IiBmaWxsPSIjMWI4ZjU5IiBjbGFzcz0iZmlsbC0wMDAwMDAiPjwvcGF0aD48L3N2Zz4=" />
						:
							<img width={48} src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDY2LjI3IDIyNS4zMWM0LjY3NC0yMi42NDcuODY0LTQ0LjUzOC04Ljk5LTYyLjk5IDIuOTU4LTIzLjg2OC00LjAyMS00OC41NjUtMTcuMzQtNjYuOTlDNDM4Ljk4NiAzOS40MjMgNDA0LjExNyAwIDMyNyAwYy03IDAtMTUgLjAxLTIyLjIyLjAxQzIwMS4xOTUuMDEgMTY4Ljk5NyA0MCAxMjggNDBoLTEwLjg0NWMtNS42NC00Ljk3NS0xMy4wNDItOC0yMS4xNTUtOEgzMkMxNC4zMjcgMzIgMCA0Ni4zMjcgMCA2NHYyNDBjMCAxNy42NzMgMTQuMzI3IDMyIDMyIDMyaDY0YzExLjg0MiAwIDIyLjE3NS02LjQzOCAyNy43MDgtMTZoNy4wNTJjMTkuMTQ2IDE2Ljk1MyA0Ni4wMTMgNjAuNjUzIDY4Ljc2IDgzLjQgMTMuNjY3IDEzLjY2NyAxMC4xNTMgMTA4LjYgNzEuNzYgMTA4LjYgNTcuNTggMCA5NS4yNy0zMS45MzYgOTUuMjctMTA0LjczIDAtMTguNDEtMy45My0zMy43My04Ljg1LTQ2LjU0aDM2LjQ4YzQ4LjYwMiAwIDg1LjgyLTQxLjU2NSA4NS44Mi04NS41OCAwLTE5LjE1LTQuOTYtMzQuOTktMTMuNzMtNDkuODR6TTY0IDI5NmMtMTMuMjU1IDAtMjQtMTAuNzQ1LTI0LTI0czEwLjc0NS0yNCAyNC0yNCAyNCAxMC43NDUgMjQgMjQtMTAuNzQ1IDI0LTI0IDI0em0zMzAuMTggMTYuNzNIMjkwLjE5YzAgMzcuODIgMjguMzYgNTUuMzcgMjguMzYgOTQuNTQgMCAyMy43NSAwIDU2LjczLTQ3LjI3IDU2LjczLTE4LjkxLTE4LjkxLTkuNDYtNjYuMTgtMzcuODItOTQuNTRDMjA2LjkgMzQyLjg5IDE2Ny4yOCAyNzIgMTM4LjkyIDI3MkgxMjhWODUuODNjNTMuNjExIDAgMTAwLjAwMS0zNy44MiAxNzEuNjQtMzcuODJoMzcuODJjMzUuNTEyIDAgNjAuODIgMTcuMTIgNTMuMTIgNjUuOSAxNS4yIDguMTYgMjYuNSAzNi40NCAxMy45NCA1Ny41NyAyMS41ODEgMjAuMzg0IDE4LjY5OSA1MS4wNjUgNS4yMSA2NS42MiA5LjQ1IDAgMjIuMzYgMTguOTEgMjIuMjcgMzcuODEtLjA5IDE4LjkxLTE2LjcxIDM3LjgyLTM3LjgyIDM3LjgyeiIgZmlsbD0iI2QxMDAwMCIgY2xhc3M9ImZpbGwtMDAwMDAwIj48L3BhdGg+PC9zdmc+" />
						}
						
						<p>Спробуєм ще?</p>
						
						<div className={'controls'}>
							<button id="next" onClick={next}>Далі</button>
						</div>
					</>
				:
					<div className={'controls ' + correctAnwserPosition}>
						{controls}
					</div>
				}

				<p>Вигадав Данілейченко Тимофій :)</p>
			</div>
		</div>
	);
}

export default App;
